$(function(){

    $("form.create-story").submit(function(e){
        e.preventDefault();

        // Gather items we need.
        var form = $(this);
        var formDump = form.serializeArray();
        var thumb = form.find("*[name=thumbnail]").prop('files');

        $("body").removeClass("pace-done").addClass("pace-running");
        $(".pace").removeClass("pace-inactive").addClass("pace-active");

        // Are there any images? Upload these first.
        if (thumb.length!=0) {

            var file = thumb[0];
            var fileNiceName = file.name.split('.')[0];
            var formData = new FormData();
            formData.append('file', file);
            formData.append('nicename', fileNiceName);
            formData.append('is_public', true);
            formData.append('context', 'STORYIMAGE_SUBMISSION');

            LightSpeed.apiCall('storage', formData, 'POST',
                function(response){
                    // Add image data to the formDump
                    formDump.push(
                        {
                            name:"thumbnail",
                            value:response.data.storage.id
                        }
                    )
                    saveStory(formDump);
                },
                handleError, true)
        }else{
            saveStory(formDump);
        }


    });

    function saveStory(data){
        console.log(data);

        var d = new Date();

        var payload = {
            is_public:1,
            site_cms_type_id:113,
            slug: 'user-story-'+d.getFullYear()+'-'+d.getMonth()+'-'+d.getDay(),
            status:'PUBLISHED',
            fields: []
        };

        // parse form data into LS accepted payload.
        data.forEach(function(field){

            // Is this field a custom field?
            if(field.name.indexOf(":")!=-1){
                var fieldDetails = field.name.split(':');

                // 0 = field (ignore)
                // 1 = ID
                // 2 = Name
                // 3 = Format

                var fieldObject = {
                    context:"site_cms_type_field_id",
                    format:fieldDetails[3],
                    id:parseInt(fieldDetails[1]),
                    name:fieldDetails[2],
                    value:field.value
                }

                payload.fields.push(fieldObject);

            }else{

                payload[field.name] = field.value;

            }
        });

        console.log(payload);

        // Send payload to api.
        // On success, updateTimeline with new story ID
        LightSpeed.apiCall('stories', payload, 'POST', updateTimeline, handleError);

    }

    function updateTimeline(response){

        // get Timeline.
        var timeline = localStorage.getItem('editContent');
        if(timeline=='' || typeof timeline === 'undefined'){
            handleStringError("Timeline not defined");
            return false;
        }
        timeline = parseInt(timeline);
        var storyId = response.data.container.id;

        LightSpeed.apiCall('timelines/'+timeline, null, 'GET',
            function(response){
                console.log("Got Timeline");
                console.log(response);

                var title = response.data.container.title;
                var slug = response.data.container.slug;

                // Parse Stories into array.

                var stories = JSON.parse(response.data.container.fields.stories);

                console.log(stories);

                if(typeof stories !== 'object'){
                    stories = JSON.parse(stories);
                }

                var payload = {
                    title:title,
                    stories: []
                }

                stories.forEach(function(story){
                    console.log(story);
                    payload.stories.push(story.story);
                });

                payload.stories.push(storyId);

                // Update Timeline with new stories
                LightSpeed.apiCall('timelines/'+timeline, payload, 'PUT',
                    function(response){
                        window.location.href = '/timelines/'+slug;
                    },
                    handleError
                );

                // On Success, redirect to create timeline page.







            },
            handleError
        )




    }

    function handleError(response){
        console.log("Something failed");
        console.log(response);

        $("body").addClass("pace-done").removeClass("pace-running");
        $(".pace").addClass("pace-inactive").removeClass("pace-active");
    }

});