if ($('.quiz.card').length > 0) {

    $('.quiz.card input[type=radio]').on("change", function() {
        var card = "#question-" + $(this).attr("data-question");
        var answerID = $(this).attr("id");
        var status = 'incorrect';
        if($(this).hasClass("correct")){
            var status = 'correct';
        } else{
            $("#incorrect-" + answerID).prop("checked", true);
        }

        $(card).removeClass("incorrect");
        $(card).addClass(status);

        var cardHeight = $(".quiz.card-correct-overlay").actual('height');

        alert(cardHeight);

        $(card).height(cardHeight);



    });

}