$(function(){

    if($(".edit-timeline .stories").length!=0) {
        $(".edit-timeline .stories").sortable({
            placeholder: "story-placeholder",
            handle: ".fas",
            items: '> li',
            helper: 'clone',
        });
    }


    $(".edit-timeline").submit(function(e){

        e.preventDefault();

        var timeline = $(this).data("timeline");
        var slug = $(this).data("slug");
        var payload = {
            title: $("#timeline-title").val(),
            stories: []
        }

        $(".stories .story").each(function(){
            var id = $(this).data('id');
            payload.stories.push(id);
        });

        // Update Timeline with new stories
        LightSpeed.apiCall('timelines/'+timeline, payload, 'PUT',
            function(response){
                window.location.href = '/timelines/'+slug;
            },
            handleError
        );

        return false;
    });

    $(".edit-timeline-cancel").on("click", function(){
        var slug = $(this).data("slug");
        window.location.href = '/timelines/'+slug;
    });

    function handleError(response){
        console.log("Something failed");
        console.log(response);
    }

});