$(function(){
    var sidebar = $("aside.sidebar");
    var sidebarToggle = $(".sidebar-toggle-button");
    var article = $(".main-body");

    sidebarToggle.click(function(){
        sidebar.toggleClass("open");
        sidebarToggle.toggleClass("open");
        article.toggleClass("sidebar-open");

        if(!sidebar.hasClass('open')) {
            Cookies.set('closed-sidebar', 'true');
        } else{
            Cookies.remove('closed-sidebar');
        }
    });

    if(Cookies.get()["closed-sidebar"] === "true"){
        sidebar.removeClass("open");
        sidebarToggle.removeClass("open");
        article.removeClass("sidebar-open");
    }

    if ($(window).width() < 769) {
        sidebar.removeClass("open");
        sidebarToggle.removeClass("open");
        article.removeClass("sidebar-open");
        Cookies.set('closed-sidebar', 'true');
    }

});