if ($('#story-single').length > 0) {

    $(".has-blur").addClass("is-blurred");

    $(".close-overlay .fa").click(function(){
        window.location = homeUrl;
    });

    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            window.location = homeUrl;
        }
    });

}