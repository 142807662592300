$(".share-button .main-button").click(function(e){
    var box = $(this).parent().find('.share-box');
    box.addClass("open");
    e.preventDefault();
});

$(".story-card-share-button .main-button").click(function(e){
    var box = $(this).parent().find('.share-box');
    box.addClass("open");
    e.preventDefault();
});

$(document).mouseup(function(e)
{
    var container = $(".share-box");
    if (!container.is(e.target) && container.has(e.target).length === 0)
    {
        container.removeClass("open");
    }
});


$(".share-link.tw").click(function() {
    $(".share-box").removeClass("visible");
    var e, t, n = "status=no,height=300,width=600,resizable=yes,left=" + (e = window.screen.width / 2 - 310) + ",top=" + (t = window.screen.height / 2 - 200) + ",screenX=" + e + ",screenY=" + t + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no",
        o = "https://twitter.com/share?url=" + $(this).attr("data-url");
    return window.open(o, "", n), !1
}), $(".share-link.fb").click(function() {
    $(".share-box").removeClass("visible");
    var e, t, n = "status=no,height=700,width=600,resizable=yes,left=" + (e = window.screen.width / 2 - 310) + ",top=" + (t = window.screen.height / 2 - 400) + ",screenX=" + e + ",screenY=" + t + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no",
        o = "https://www.facebook.com/sharer/sharer.php?u=" + $(this).attr("data-url");
    return window.open(o, "", n), !1
}), $(".share-link.gp").click(function() {
    $(".share-box").removeClass("visible");
    var e, t, n = "status=no,height=700,width=500,resizable=yes,left=" + (e = window.screen.width / 2 - 260) + ",top=" + (t = window.screen.height / 2 - 400) + ",screenX=" + e + ",screenY=" + t + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no",
        o = "https://plus.google.com/share?url=" + $(this).attr("data-url");
    return window.open(o, "", n), !1
})



$(".card .like").each(function() {
    var id = parseInt($(this).data("id"));
    if(Cookies.get()[id + '-liked'] === "true"){
        $(this).addClass("liked");
    }
});

$(".story-overlay .like").each(function() {
    var id = parseInt($(this).data("id"));
    if(Cookies.get()[id + '-liked'] === "true" && loggedIn === true){
        $(this).addClass("liked");
    }
});


$(".like").click(function(e){

    var id = parseInt($(this).data("id"));
    var currentLikes = parseInt($(this).data('current-likes'));
    var likeContainer = $(this).find('span');
    var button = $(this);

    var payload = {
        context: "CONTAINER",
        reference_id: id,
        interaction: "LIKE"
    }

    ls.apiCall('reaction', payload, 'POST',
        function (response) {
            // Success!
            console.log("Reaction Saved!");
            console.log(response);
            likeContainer.text( (currentLikes+1) );
            button.addClass("liked");
            Cookies.set(id + '-liked', 'true');
        },
        function (response) {
            // Fail :(
            console.log("Reaction Failed");
            console.log(response);
            likeContainer.closest('.row').append('<p class="alert bg-danger text-white col-12 mt-4 text-center"><strong>Something went wrong</strong></p>');

        });

    e.preventDefault();

});