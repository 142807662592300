$(function(){

    $(".add-existing-story-card").click(function(){

        var storyId = $(this).data("story-id");
        var timeline = localStorage.getItem('editContent');

        $(this).addClass("processing");

        LightSpeed.apiCall('timelines/'+timeline, null, 'GET',
            function(response){
                console.log("Got Timeline");
                console.log(response);

                var title = response.data.container.title;
                var slug = response.data.container.slug;

                // Parse Stories into array.

                var payload = {
                    title: title,
                    stories: []
                }

                if(response.data.container.fields.stories) {
                    var stories = JSON.parse(response.data.container.fields.stories);

                    if (typeof stories !== 'object') {
                        stories = JSON.parse(stories);
                    }

                    stories.forEach(function (story) {
                        console.log(story);
                        payload.stories.push(story.story);
                    });
                }

                payload.stories.push(storyId);

                // Update Timeline with new stories
                LightSpeed.apiCall('timelines/'+timeline, payload, 'PUT',
                    function(response){
                        window.location.href = '/timelines/'+slug;
                    },
                    handleError
                );

                // On Success, redirect to create timeline page.







            },
            handleError
        )

    });

    function handleError(response){
        console.log("Something failed");
        console.log(response);
    }

});
