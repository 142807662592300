window.formUtils = {};

window.formUtils.showFormError = function(msg) {
    $(window.formId).find("*[type='submit']").text("Re-send").attr("disabled", false);
    $(window.formId).find(".alert-danger").text(msg).show();
    window.submitting = false;
};

window.formUtils.handleApiError = function(err) {
    if (err.message == 'The file "" does not exist') {
        window.formUtils.showFormError('The file is too big');
        return;
    }
    try {
        var code = err.data.errors[0].code;
        if (!code) {
            throw "No code returned";
        }
        var msg;
        switch(code) {
            case 110001:
            case 110002:
            case 120001:
                msg = 'You need to log in to perform this action';
                break;
            default:
                msg = 'Sorry, there was a problem submitting the form'
        }
        window.formUtils.showFormError(msg);
    }
    catch (e) {
        window.formUtils.showFormError('Sorry, there was a problem submitting the form');
    }
}

window.formUtils.prepSubmit = function(msg) {
    window.submitting = true;
    // hide previous errors
    $(window.formId).find(".alert").hide();

    // show loading state
    $(window.formId).find("*[type='submit']").html('Sending &nbsp;&nbsp;<i class="fa fa-spinner fa-spin"></i>').attr("disabled", true);

    if(msg) {
        $(window.formId).find(".alert-success").text(msg).show();
    }

}

window.formUtils.showSuccess = function(msg) {
    $(window.formId).html('<div class="alert alert-success always-visible">' + msg + '</div>');
    window.submitting = false;
}