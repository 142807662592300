function loadImagesNormally(){
    $("img.card-lazyload, img.card-lazyload, img.card-lazyload, img.card-lazyload, img.card-lazyload").each(function (index) {
        var src = $(this).attr('src');
        if (typeof src === 'undefined' || src === false) {
            $(this).attr('src', $(this).attr('data-src'));
        }
    });
}

if(screen.width > 992) {
    var slider = $("#slider").flipster({
        style: 'carousel',
        spacing: -0.3,
        scrollwheel: false,
        buttons: true,
        start: 0,
        loop: true,
        onItemSwitch: changeYearInIndicator
    });

    function changeYearInIndicator(current, prev) {
        if ($('.year-slider ul').is(":visible")) {
            var card_num = $(".flipster__container li").index(current);
            $("#indicator-block-" + card_num).click();
            yearSlider.goToSlide(card_num);
        }

        loadSliderImages()
    }

    function loadSliderImages() {
        $(".flipster__item--current img.card-lazyload, .flipster__item--future-1 img.card-lazyload, .flipster__item--future-2 img.card-lazyload, .flipster__item--past-1 img.card-lazyload, .flipster__item--past-2 img.card-lazyload").each(function (index) {
            var src = $(this).attr('src');
            if (typeof src === 'undefined' || src === false) {
                $(this).attr('src', $(this).attr('data-src'));
            }
        });
    }

    loadSliderImages()

    if ($('.year-slider ul').is(":visible")) {
        $(".year-slider-old").show();
        var yearSlider = $('.year-slider ul').bxSlider({
            pager: false,
            prevText: '<i class="fal fa-chevron-left" aria-hidden="true"></i>',
            nextText: '<i class="fal fa-chevron-right" aria-hidden="true"></i>',
            onSlideBefore: function ($slideElement, oldIndex, newIndex) {
                slider.flipster('jump', newIndex);
            }
        });
        if(window.startIndex) {
            setTimeout(function () {
                yearSlider.goToSlide(window.startIndex)
            }, 500);
        }
    }
} else{
    loadImagesNormally()
}


if($(".timeline-list").length){
    loadImagesNormally();
}
