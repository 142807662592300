$(function(){

    $("*[data-provide=datetimepicker]").each(function(){
        $(this).datetimepicker({
            format: 'DD/MM/YYYY',
        }).on("dp.change", function(e){
            var dateLabel = $('*[name="date_label"]');

            if(
                (typeof parseInt(dateLabel.val()) !== 'NaN')
                && (dateLabel.val().length==4 || dateLabel.val()=='')
            ){
                dateLabel.val(e.date.format('YYYY'));
            }

        });
    });
});