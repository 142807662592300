$(function(){

    /**
     * Handle the contact form submissions
     */
    $("#teacher-premium-form").submit(function(e){
        e.preventDefault();

        //Sets the loading icon
        var submitButton = $(this).find("*[type=submit]");
        submitButton.html('Sending &nbsp;&nbsp;<span class="fa fa-spinner fa-spin"></span>').addClass("sending");

        //Get the form ID
        var formId = $(this).data('form-id');

        // Create object with all the form data
        var formData = {
            name: $(this).find("input[name=name]").val(),
            email: $(this).find("input[name=email]").val(),
            school: $(this).find("input[name=school]").val(),
            message: $(this).find("textarea[name=message]").val(),
            subject: $(this).data('subject'),
            fields: {
                //When data is sent it will json encode if this is blank it will break
                test: "test"
            }
        };

        console.log(formData);

        // Submits the form
        LightSpeed.apiCall('contact/'+formId+'/submission', formData, 'POST', function(response){
                contactFormResponse("#teacher-premium-form");
            },
            function(response){
                $("#teacher-premium-form").find("*[type=submit]").text("Re-send").removeClass("sending");
            });
    });

    $("#request-story-form").submit(function(e){
        e.preventDefault();

        //Sets the loading icon
        var submitButton = $(this).find("*[type=submit]");
        submitButton.html('Sending &nbsp;&nbsp;<span class="fa fa-spinner fa-spin"></span>').addClass("sending");

        //Get the form ID
        var formId = $(this).data('form-id');

        // Create object with all the form data
        var formData = {
            name: $(this).find("input[name=name]").val(),
            email: $(this).find("input[name=email]").val(),
            contact_number: $(this).find("input[name=number]").val(),
            message: $(this).find("textarea[name=message]").val(),
            subject: $(this).data('subject'),
            fields: {
                //When data is sent it will json encode if this is blank it will break
                test: "test"
            }
        };

        console.log(formData);

        // Submits the form
        LightSpeed.apiCall('contact/'+formId+'/submission', formData, 'POST', function(response){
                contactFormResponse("#request-story-form");
            },
            function(response){
                $("#request-story-form").find("*[type=submit]").text("Re-send").removeClass("sending");
            });
    });



    $("#contact-us-form").submit(function(e){
        e.preventDefault();

        //Sets the loading icon
        var submitButton = $(this).find("*[type=submit]");
        submitButton.html('Sending &nbsp;&nbsp;<span class="fa fa-spinner fa-spin"></span>').addClass("sending");

        //Get the form ID
        var formId = $(this).data('form-id');

        // Create object with all the form data
        var formData = {
            name: $(this).find("input[name=name]").val(),
            email: $(this).find("input[name=email]").val(),
            message: $(this).find("textarea[name=message]").val(),
            subject: $(this).data('subject'),
            fields: {
                //When data is sent it will json encode if this is blank it will break
                test: "test"
            }
        };

        console.log(formData);

        // Submits the form
        LightSpeed.apiCall('contact/'+formId+'/submission', formData, 'POST', function(response){
                contactFormResponse("#contact-us-form");
            },
            function(response){
                $("#contact-us-form").find("*[type=submit]").text("Re-send").removeClass("sending");
            });
    });


    /**
     * Resets the form and sets the complete message for the contact form
     */
    function contactFormResponse(formId){
        var thankYouText = $(formId).attr("data-thank-you-message");
        $(formId).find(".response").html(thankYouText);
        $(formId).find("*[type=submit]").hide().removeClass("sending");
        $(formId).find('input, select, textarea').empty().val('');
    }


        $(".update-details").click(function(e) {
            e.preventDefault();

            $(this).find(".fa").removeClass("fa-save").addClass("fa-spinner fa-spin");

            var form = $(this).parent().find('form');

            form.find(".error").remove();

            var formData = {};

            var detailsForm = $(".update-details-form");

            formData['display_name'] = detailsForm.find("input[name=title]").val() + ' ' + detailsForm.find('input[name=first_name]').val() + ' ' + detailsForm.find('input[name=last_name]').val();
            formData['title'] = detailsForm.find("input[name=title]").val();
            formData['first_name'] = detailsForm.find("input[name=first_name]").val();
            formData['last_name'] = detailsForm.find("input[name=last_name]").val();
            formData['email_address'] = detailsForm.find("input[name=email_address]").val();

            form.each(function () {

                $(this).find('input').each(function () {

                    var name = $(this).attr('name');
                    var value = $(this).val();

                    if (value == '') {
                        value = null;
                    }
                    formData[name] = value;

                });

            });


            LightSpeed.apiCall('account', formData, 'PUT', function (response) {
                    contactFormResponse(".update-details");
                },
                function (response) {
                    $(".update-details").find(".response").html("Something went wrong, please try again");
                    $(".update-details").find("*[type=submit]").hide().removeClass("updating");
                    $(".update-details").find('input, select, textarea').empty().val('');
                });
        });


});