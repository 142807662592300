var map;
var markerList;
var mapMarkers = [];
var mapLat = 0;
var mapLng = 0;
var bounds;
function initMap(fieldString = "*[data-provide^='google-map']:visible") {
    // // Create a map object and specify the DOM element for display.
    // bounds = new google.maps.LatLngBounds();
    //
    // var mapObject = $(fieldString);
    //
    // mapObject.each(function() {
    //     //$(this).attr('data-provide', null);
    //     var id = $(this).attr('id');
    //
    //     markerList = $(this).find('.marker');
    //     var bounds = new google.maps.LatLngBounds();
    //
    //
    //     mapLat = $(this).data("lat");
    //     mapLng = $(this).data("lng");
    //
    //     map = new google.maps.Map(document.getElementById(id), {
    //         center: new google.maps.LatLng(mapLat,mapLng),
    //         zoom: parseInt(15),
    //         disableDefaultUI: true
    //     });
    //
    //
    //     markerList.each(function(){
    //         addMarker($(this));
    //     });
    //
    // });
}


function addMarker(marker){
    var lat = marker.data('lat');
    var lng = marker.data('lng');
    var icon = marker.data('icon');

    var iconSize = new google.maps.Size(45,45);

    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat,lng),
        map: map,
        optimized:false,
    });

    mapMarkers.push(marker);
}


function initCreateMap(){

    var mapElem = $(".map");

    var geocoder = new google.maps.Geocoder();
    //maps.each(function(){
    var lat = mapElem.data("lat");
    var lng = mapElem.data("lng");

    var map = new google.maps.Map(mapElem[0], {
        center:{lat:lat, lng:lng},
        zoom:11
    });

    var mapId = mapElem.data('autocomplete');

    var markerLocation = {};

    var bounds = new google.maps.LatLngBounds();

    var placeSearch, autocomplete, marker;

    var autoElement = $("#"+mapId+"_addressInput")[0];
    var inputElement = $("#"+mapId);

    console.log(mapId);
    console.log(inputElement);
    console.log(inputElement.val());

    map.addListener('zoom_changed', function(){
        markerLocation.zoom = map.getZoom();
        inputElement.val(JSON.stringify(markerLocation));
    });

    if(inputElement.val()!='' && typeof inputElement.val() !== 'undefined'){
        markerLocation = JSON.parse(inputElement.val());
        //console.log(obj);

        if(typeof markerLocation.geometry !== 'undefined'){
            marker = new google.maps.Marker({
                map:map,
                position:markerLocation.geometry.location,
                draggable:true
            });
            marker.addListener('dragend', function(){
                geocodePosition(marker.getPosition(), $(autoElement), inputElement, markerLocation);
            });
            bounds.union(markerLocation.geometry.viewport);
            map.fitBounds(bounds);
            autoElement.value = markerLocation.address;
        }
    }

    autocomplete = new google.maps.places.Autocomplete(
        (autoElement),
        {types: ['geocode']}
    );

    autocomplete.addListener('place_changed', function(){

        if(typeof marker !== 'undefined') {
            marker.setMap(null);
        }


        var place = autocomplete.getPlace();

        marker = new google.maps.Marker({
            map:map,
            title:place.formatted_address,
            position:place.geometry.location,
            draggable:true
        });
        marker.addListener('dragend', function(){
            geocodePosition(marker.getPosition(), $(autoElement), inputElement, markerLocation);
        });

        if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
        } else {
            bounds.extend(place.geometry.location);
        }

        map.fitBounds(bounds);


        updateLocation(markerLocation, place, inputElement);


        console.log(place);

        console.log(markerLocation);
    });

    //});

    function updateLocation(markerLocation, place, input){
        markerLocation.address = place.formatted_address;
        markerLocation.lat = place.geometry.location.lat();
        markerLocation.lng = place.geometry.location.lng();
        markerLocation.geometry = place.geometry;

        place.address_components.forEach(function(component){
            if(component.types.indexOf('country') != -1){
                markerLocation.country = component.long_name;
            }
            if(component.types.indexOf('administrative_area_level_2') != -1){
                markerLocation.region = component.long_name;
            }
        });

        input.val(JSON.stringify(markerLocation)).trigger('change');
    }

    function geocodePosition(pos, input, inputElement, markerLocation) {
        geocoder.geocode({
            latLng: pos
        }, function(responses) {
            console.log(responses);
            if (responses && responses.length > 0) {
                input.val(responses[0].formatted_address);
                updateLocation(markerLocation, responses[0], inputElement);
            } else {
                input.val('Cannot determine address at this location.');
            }
        });
    }
}



// If the element is in the DOM, load the script
$(function(){
    if($('*[data-provide="google-map"]').length!=0){
        loadScript('https://maps.googleapis.com/maps/api/js?key='+gmapsKey+'&callback=initMap&v=3.exp&use_slippy=true');
    }
});

$(function(){
    if($('*[data-provide="google-map-no-callback"]').length!=0){
        loadScript('https://maps.googleapis.com/maps/api/js?key='+gmapsKey+'&v=3.exp&use_slippy=true');
    }
});

$(function(){
    if($('*[data-provide="google-map-create"]').length!=0){
        loadScript('https://maps.googleapis.com/maps/api/js?key='+gmapsKey+'&libraries=places&callback=initCreateMap&v=3.exp&use_slippy=true');
    }
});
