var thumbnailChange = false;

$(function(){

    if ($(".create-story").length > 0) {

        $(".create-story").find(".dropify").dropify();

    }

});