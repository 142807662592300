if ($('#search-single').length > 0) {

    $(".has-blur").addClass("is-blurred");

    $(".close-overlay .fa").click(function(){
        window.location = homeUrl;
    });

    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            window.location = homeUrl;
        }
    });
}

if ($('#search-results').length > 0) {

    $(".categories-navigation ul li a").each(function (index, element) {
        var slug = $(this).attr("data-category-slug");

        var currentUrl = window.location.href;

        var parsedUrl = $.url(currentUrl);

        var params = parsedUrl.param();
        params["category"] = slug;

        var newUrl = "?" + $.param(params);

        $(this).attr("href", newUrl);

    });
}