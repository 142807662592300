$(function(){

    $(".story.card.add").click(function(){
        $(".overlay.add-story").addClass("open");
        $(".has-blur").addClass("is-blurred");
    });

    if($(".user-timeline").length!=0){

        // Get timeline ID and userID from the user timeline DOM.
        var timelineId = $(".user-timeline").data("content-id");
        var userId = $(".user-timeline").data("user-id");

        // If the timeline is new, the ID will be 'create'. Means we need to make a draft one.
        if(timelineId=='create'){


            // If we've created one already, it will be stored in LocalStorage, until it's saved. Consider this an autosave.
            /*if(localStorage.getItem('tempContent')!==null){
                window.userContent = localStorage.getItem('tempContent');
                localStorage.setItem("editContent", window.userContent);
                $(".user-timeline").attr('data-content-id', window.userContent);
                return false;
            }*/


            // Create new user timeline.
            var d = new Date();
            console.log(d);

            // Build the payload
            var payload = {
                title: $("#new-timeline-title").html(),
                stories:'[]'
            }

            console.log(payload);

            // Fire off the API Call to create a draft timeline.
            LightSpeed.apiCall('timelines', payload, 'POST', tempPublish, tempFail);

        }else{
            window.userContent = timelineId;
        }
    }

    function tempPublish(response){
        // it worked!

        console.log(response);
        // Store the timeline ID in localstorage, for when we come back to it later.
        localStorage.setItem('tempContent', response.data.container.id);
        localStorage.setItem('editContent', response.data.container.id);
        window.userContent = response.data.container.id;
        $(".user-timeline").attr('data-content-id', window.userContent);
    }

    function tempFail(response){
        // Alert the user that something failed.
        console.log(response);
    }

});