// $(function(){
//     $("form.request-story-form").submit(function(e){
//         e.preventDefault();
//
//         var formId = $(this).data("form-id");
//         var messageContainer = $(this).find(".message");
//         var submitButton = $(this).find('*[type=submit]');
//
//         if(!formId){
//             messageContainer.html("No form ID found.").addClass("has-error");
//             return false;
//         }
//
//         var payload = {
//             name:$("*[name=fullName]").val(),
//             email:$("*[name=email]").val(),
//             number:$("*[name=number]").val(),
//             message:$("*[name=message]").val(),
//             fields: {
//                 subject: $("*[name=subject]").val(),
//                 test: "test"
//             },
//         };
//
//         submitButton.find(".far").addClass("fa-spinner-third");
//
//         apiCall('contact/'+formId+'/submission', payload, 'POST', function(response){
//                 messageContainer.html('Your form has been submitted.').addClass("success");
//                 submitButton.remove();
//             },
//             function(response){
//                 var errorText = '<ul>';
//                 try {
//                     response.data.errors.forEach(function(error){
//                         errorText += '<li>'+error.message+'</li>';
//                     });
//                 }
//                 catch(err) {
//                     errorText += '<li>Sorry there was a problem submitting the form</li>';
//                 }
//                 errorText += '</ul>';
//                 messageContainer.html(errorText).addClass('has-error');
//                 submitButton.text("Resend").find(".far").removeClass("fa-spinner-third");
//             });
//
//     });
// });