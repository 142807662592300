var current_subcat = $(".year-slider ul li.current");
var current_index = current_subcat.index();
var blocks_width = $(".blocks-percent").width();
var card_count = $(".card").length;

//Add one green block for each card in the secondary nav
if(screen.width > 767) {
    for (var i = 0; i < card_count; i++) {
        $(".time-bar .blocks-percent").append("<li id='indicator-block-" + i + "' data-num='" + i + "'></li>");
    }

//Go to cards in carousel on secondary nav click
    setTimeout(function () {
        $("ul.time-bar .blocks-percent li").click(function () {
            var num = parseInt($(this).attr("data-num"));
            var position = $(this).position();
            var left = (position.left + $(this).width() / 2) - 5;

            $("ul.time-bar blocks-percent li").removeClass("active");
            $(this).addClass("active");
            $("ul.time-bar .indicator").css({"left": left});
            slider.flipster('jump', num);
        });

        var smallest_year = $(".year-slider ul > :last-child").text();
        var largest_year = $(".year-slider ul > :first-child").text();

        $(".time-bar .first-year").html("<span>" + smallest_year + "</span>");
        $(".time-bar .last-year").html("<span>" + largest_year + "</span>");

    }, 200);

//Move indicator to first card n load
    setTimeout(function () {
        if ($("ul.time-bar .indicator").length > 0) {
            $(".blocks-percent > :first-child").click();
        }
    }, 200);

//Make indicator draggable and update carousel/year slider
    $("ul.time-bar .indicator").draggable({
        axis: "x",
        containment: ".blocks-percent",
        drag: function (event, ui) {
            var l = ui.position.left;

            $(".blocks-percent li").each(function () {
                var position = $(this).position();
                if (l >= position.left && l <= (position.left + $(this).width())) {
                    $(this).trigger('click');
                }
            });
        }
    });
}
