if ($('#select-a-story').length > 0) {

    $("#clear").on("click", function(){
        window.location = "/select-a-story";
    });

    $('input[type=radio][name=category]').change(function() {
        var currentUrl = window.location.href.split('?')[0];
        window.location = currentUrl + '?category=' + this.value;
        if(this.value === "clear"){
            window.location = currentUrl;
        }
    });
}