var redactorOptions = {
    imagePosition: true,
    imageResizable: true,
    autoparseImages: false,
    pasteImages: false,
    formatting: ['p', 'h3', 'h4', 'h5', 'h6', 'blockquote'],
    minHeight: '300px',
    spellCheck: true,
    structure: true,
    multipleUpload: false,
    buttons: ['format', 'bold', 'italic', 'deleted', 'ol', 'ul', 'indent', 'outdent', 'sup', 'sub', 'link', 'line', 'redo', 'undo'],
    plugins: ['alignment', 'video'],
    imageUpload: function (formData, files, event, upload) {
        return new Promise(function (resolve, reject) {

            var storageData = [];
            var imageResponse = [];

            Array.prototype.forEach.call(files, function (file) {

                var thisData = new FormData();
                thisData.append('file', file);
                thisData.append('nicename', file.name);
                thisData.append('is_public', true);

                storageData.push(thisData);
            });

            var uploadCount = 0;

            storageData.forEach(function (data) {
                apiCall('storage', data, 'POST', uploadComplete, uploadFailed, true);
            });

            function uploadComplete(response) {
                uploadCount++;
                //imageResponse['file-'+uploadCount] = {
                //    "url":response.data.storage.public_url,
                //    "id":response.data.storage.id
                //}
                imageResponse = response.data.storage.public_url;

                isFinished();
            }

            function uploadFailed(response) {
                reject(response);
            }

            function isFinished() {
                if (uploadCount == storageData.length) {
                    resolve(imageResponse);
                }
            }


        }).then(function (response) {
            // Success
            upload.complete(response);
        }).catch(function (response) {
            // fail
            upload.complete(response);
        });


    },
    imageManagerJson: '/api/admin/ajax/media',
    callbacks: {
        image: {
            resized: function (image) {
                image.nodes.forEach(function (elem) {
                    var jQElem = $(elem);
                    var tmpSrc = jQElem.attr('src');
                    var height = jQElem.height();
                    var width = jQElem.width();

                    if (!jQElem[0].hasAttribute('data-original-src')) {
                        jQElem.attr('data-original-src', tmpSrc);
                    }

                    var currentSrc = jQElem.attr("data-original-src");


                    console.log(currentSrc.indexOf('/storage/'));

                    if (currentSrc.indexOf('/storage/') == 0) {
                        var newSrc = currentSrc.replace('/storage/', '/storage/thumbs/' + parseInt(width) + 'x' + parseInt(height) + '/');
                        jQElem.attr('src', newSrc);
                    }
                });
            },
            inserted: function (image) {

                console.log(image);
                setTimeout(function () {

                    image.nodes.forEach(function (elem) {
                        var jQElem = $(elem).find('img');
                        var tmpSrc = jQElem.attr('src');
                        var height = jQElem.height();
                        var width = jQElem.width();

                        if (!jQElem[0].hasAttribute('data-original-src')) {
                            jQElem.attr('data-original-src', tmpSrc);
                        }

                        var currentSrc = jQElem.attr("data-original-src");


                        console.log(currentSrc.indexOf('/storage/'));

                        if (currentSrc.indexOf('/storage/') == 0) {
                            var newSrc = currentSrc.replace('/storage/', '/storage/thumbs/' + parseInt(width) + 'x' + parseInt(height) + '/');
                            jQElem.attr('src', newSrc);
                        }
                    });
                }, 500);
            }
        }
    }
}

$(function() {
    $("*[data-provide='redactor']").each(function () {
        $(this).redactor(redactorOptions);
    });
});
