function closeOverlay(event, url)
{
    var stateObj = { foo: "bar" };
    history.pushState(stateObj, "", url);
    $(".close-overlay").attr("url", "");

    $(".overlay").removeClass("open");
    $(".has-blur").removeClass("is-blurred");
    event.preventDefault();
}

function openOverlay(overlay, event)
{
    if ( typeof overlay !== 'undefined') {
        $("." + overlay + "-overlay").toggleClass("open");
        $(".has-blur").addClass("is-blurred");
    }

    if(overlay == "search"){
        var currentUrl = window.location.href;
        $(".overlay .close-overlay").attr("data-url", currentUrl);

        var stateObj = { foo: "bar" };
        history.pushState(stateObj, "", homeUrl + '/search');

        $("#search-input").focus();
    }

    if (overlay.match("^story-")) {
        var currentUrl = window.location.href;
        $(".overlay .close-overlay").attr("data-url", currentUrl);

        initMap("." + overlay + "-overlay .map");

        var card = $('[data-overlay="'+overlay+'"]');
        var url = card.attr("data-url");
        var stateObj = { foo: "bar" };

        history.pushState(stateObj, "", url);
    }

    event.preventDefault();
}

$(function(){

    $(document).on("click", ".open-overlay", function(e){
        var overlayType = $(this).attr("data-overlay");
        var url = $(this).attr("data-url");

        if ( typeof url !== 'undefined') {
            // alert(url);
        }

        openOverlay(overlayType, e);
    });

    $(document).on("click", ".close-overlay", function(e){
        var url = $(this).attr("data-url");
        closeOverlay(e, url);
    });

    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            if ($(".close-overlay").data('url')) {
                url = $(".close-overlay").attr("data-url");
                closeOverlay(e, url);
            } else{
                closeOverlay(e);
            }
        }
    });

});
